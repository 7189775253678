const env = "pro"; // local:本机调试;dev:开发环境,test:预发环境,pro:正式环境
let baseUrl = "";
let isMobile = true;   //判断是否移动端
switch (env) {
    case "local":
        baseUrl = "";
        break;
    case "dev":
        baseUrl = "http://10.10.20.228:6060";
        break;
    case "test":
        baseUrl = "";
        break;
    case "pro":
        baseUrl = "";
        break;
    default:
        break;
}

const machineType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ? 'Mobile' : 'Desktop';
if (machineType === 'Mobile') {
    isMobile = true
} else {
    isMobile = false
}


export default {
    env,
    baseUrl,
    isMobile
}
