<template>
  <div class="box">
    <div v-if="!isHas" class="pic">
      <img v-if="isAlready" src="@/assets/info.png" alt="">
      <img v-else src="@/assets/success.png" alt="">
      <p v-if="isAlready">该手机号已被注册</p>
      <p v-else>注册成功</p>
    </div>
    <div class="tips" :style="{'margin-top' : isHas ? '40%' : '','margin-bottom' : isHas ? '25%' : '20%'}">
      <p>前往电脑端开始免费建站</p>
      <p @click="onCopy('https://accounts.mysitefy.com/login')">https://accounts.mysitefy.com/login <img src="@/assets/copy.png" alt=""></p>
    </div>
    <div class="code">
      <p style="margin-bottom:10px">添加建站专家, 免费带练建站</p>
      <img src="@/assets/qcCode.jpg" width="130px" height="130px" alt="" />
    </div>
    <div  class="info" :class="{'info-show' : isShow}" :style="{'top' : isHas ? '45%' : '60%'}">复制成功</div>
  </div>
</template>
<script>
import global from "../../../global.js";
export default {
  components: {},
    data() {
        return {
          qrCodeData: {},
          isShow:false,
          isHas:false,
          isAlready:false
        }
    },
    computed: {
        loginUrl() {
             switch (global.env) {
                case "dev": // dev
                    return "http://10.10.20.228:3030/register";
                case "test": // test
                    return "http://testregister.linkjoint.cn/register";
                case "pro": // pro
                    return "https://accounts.mysitefy.com/register";
                default:
                    return "http://192.168.2.101:8080/register"; // 本机环境默认开发
            }
        }
    },
    created() { 
    },
    methods: {
      onCopy (val) {
            this.$copyText(val).then(() => {
                this.isShow=true
                setTimeout(() => {
                  this.isShow=false
                }, 2000);
            }, () => {
                // this.$message.error("复制失败");
            });
        },
    },
    beforeRouteEnter (to, from, next) {
        next((vm) => {
            if (to.query.status) {
                vm.isAlready = true;
            }else {
                vm.isAlready = false;
            }
            if (to.query.isHas) {
                vm.isHas = true;
            }else {
                vm.isHas = false;
            }
        });
    }
}
</script>
<style lang="scss" scoped>
.box {
  width: 100%;
  position: relative;
}
.clearfix:after{
    display:block;
    clear:both;
    content: "";
    visibility:hidden;
    height:0;
}
.clearfix{zoom:1}
  .pic {
    width: 100%;
    padding:25% 0 72px 0;
    text-align: center;
    img{
        width:70px;
    }
    p {
      text-align: center;
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
  }
  .tips {
    // margin-top: 90px;
    margin-bottom: 90px;
    p {
      text-align: center;
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 24px;
      img {
        width: 24px;
        vertical-align: bottom;
      }
    }
  }
  .code {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 21px;
    text-align: center;
  }
  .info {
    width: 68px;
    padding: 5px;
    // height: 27px;
    background: #333333;
    border-radius: 5px;
    opacity: 0;
    line-height: 17px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%,-50%);
    transition: all .5s;
  }
  .info-show {
    opacity: 0.8;
  }
 @media (max-width: 767px) {
    .pic {
      width: 100%;
      padding-bottom:25%;
    }
  .footer {
    height: 126px;
    width: 100%;
    position: fixed;
    bottom: 0;
    background: #fff;
      p {
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #0B47E8;
      text-align: center;
      .link-text {
          text-decoration: none;
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #0B47E8;
      }
    }
    .btn {
      padding: 28px 17px 20px 17px;
      .fl-btn {
        float: left;
        height: 37px;
        line-height:36px;
        text-align: center;
        width: calc(50% - 8px);
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        background: linear-gradient(116deg, #174BE9 0%, #006CFF 78%);
        border-radius: 18px;
      }
      .fr-btn {
        float: right;
        height: 34px;
        line-height:36px;
        text-align: center;
        width: calc(50% - 8px);
        border: 1px solid #174BEA;
        border-radius: 18px;
        font-size: 16px;
        font-weight: 500;
        color: #174BEA;
      }
    }
  }
 }
</style>